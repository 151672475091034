<template>
  <div class="vs-row w-full">
    <VuePerfectScrollbar :is="'div'" class="scroll-area" :settings="settings">
      <template v-if="$route.params.lang === 'ar'">
        <div class="vs-col bg-auto bg-bottom bg-no-repeat fixed rightDiv" :class="{'right-1/2 w-1/2 h-full':window.width >= 768, 'top-1/2 w-full h-3/5':window.width < 768}">
          <div class="xl:mt-10 md:mt-10 mt-8 md:mr-12 mr-8">
            <p class="md:text-2xl sm:text-xl" style="color: #00DCDC;">Be Part Of The Adventure</p>
            <p class="md:pt-3 sm:pt-1 md:text-base sm:text-sm">Please, insert your phone number</p>
            <div class="md:mt-6 sm:mt-2 mt-4 flex">
              <vs-dropdown disabled="true">
                <vs-button class="btn-drop" type="border" :icon-after="true" icon="arrow_drop_down">+966</vs-button>
              </vs-dropdown>
              <input type="text" class="mr-3 phoneInput" placeholder="Phone Number" v-model="loginData.mobile">
            </div>
            <div class="md:mt-6 sm:mt-2 mt-4 flex">
              <input type="password" class="passwordInput" placeholder="Password" v-model="loginData.password">
            </div>
            <div class="md:mt-8 sm:mt-4 mt-5">
              <vs-button class="ml-2 mb-0 rightButtons" @click="login()" color="#F89416" type="filled">Login</vs-button>
              <vs-button @click="goToHome()" class="mx-2 rightButtons" color="dark" type="border">Continue As Guuest</vs-button>
            </div>
            <p class="md:mt-8 sm:mt-4 mt-5 font-medium">Or login with</p>
            <div class="mt-3 flex">
              <GoogleLogin :params="params" :onSuccess="onSuccess" :onFailure="onFailure">
                <vs-button class="linkButtons" size="40px" radius color="" type="gradient">
                  <img src="@/assets/images/gmail.png" alt="">
                </vs-button>
              </GoogleLogin>
              <vs-button class="linkButtons" size="40px" radius color="" type="gradient">
                <img src="@/assets/images/apple.png" alt="">
              </vs-button>
              <facebook-login class="face-button button"
                appId="428511151995837"
                @login="getUserData"
                @logout="onLogout"
                @get-initial-status="getUserData">
              </facebook-login>
            </div>
          </div>
        </div>
        <div class="vs-col fixed bg-center backgroundImage" :class="{'w-1/2 h-full bg-cover':window.width >= 768, 'w-full h-1/2':window.width < 768}">
          <div class="transbox text-white" :class="{'w-1/2 h-full':window.width >= 768, 'w-full h-1/2':window.width < 768}">
            <div class="text-center md:mt-20 mt-8">
              <img class="xl:w-1/4 lg:w-1/4 md:w-1/4 w-32 m-auto" src="@/assets/images/logo.png" alt="">
              <!-- <p class="md:text-xl text-lg mt-5 md:w-2/3 w-3/4 m-auto">Lorem ipsum dolor sit amet, consectetur sadipscing elitr, sed</p> -->
              <div class="mt-12">
                <vs-button @click="goToPage('login')" class="mx-2 font-black leftButtons mb-4" type="border">Login</vs-button>
                <vs-button @click="goToPage('register')" class="mx-2 font-black leftButtons" type="border">Sign up</vs-button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="vs-col fixed bg-center backgroundImage" :class="{'w-1/2 h-full bg-cover':window.width >= 768, 'w-full h-1/2':window.width < 768}">
          <div class="transbox text-white" :class="{'w-1/2 h-full':window.width >= 768, 'w-full h-1/2':window.width < 768}">
            <div class="text-center md:mt-20 mt-8">
              <img class="xl:w-1/4 lg:w-1/4 md:w-1/4 w-32 m-auto" src="@/assets/images/logo.png" alt="">
              <!-- <p class="md:text-xl text-lg mt-5 md:w-2/3 w-3/4 m-auto">Lorem ipsum dolor sit amet, consectetur sadipscing elitr, sed</p> -->
              <div class="mt-12">
                <vs-button @click="goToPage('login')" class="mx-2 font-black leftButtons mb-4" type="border">Login</vs-button>
                <vs-button @click="goToPage('register')" class="mx-2 font-black leftButtons" type="border">Sign up</vs-button>
              </div>
            </div>
          </div>
        </div>
        <div class="vs-col bg-auto bg-bottom bg-no-repeat fixed rightDiv" :class="{'left-1/2 w-1/2 h-full':window.width >= 768, 'top-1/2 w-full h-3/5':window.width < 768}">
          <div class="xl:mt-10 md:mt-10 mt-8 md:ml-12 ml-8">
            <p class="md:text-2xl sm:text-xl" style="color: #00DCDC;">Be Part Of The Adventure</p>
            <p class="md:pt-3 sm:pt-1 md:text-base sm:text-sm">Please, insert your phone number</p>
            <div class="md:mt-6 sm:mt-2 mt-4 flex">
              <vs-dropdown disabled="true">
                <vs-button class="btn-drop" type="border" :icon-after="true" icon="arrow_drop_down">+966</vs-button>
              </vs-dropdown>
              <input type="text" class="ml-3 phoneInput" placeholder="Phone Number" v-model="loginData.mobile">
            </div>
            <div class="md:mt-6 sm:mt-2 mt-4 flex">
              <input type="password" class="passwordInput" placeholder="Password" v-model="loginData.password">
            </div>
            <div class="md:mt-8 sm:mt-4 mt-5">
              <vs-button class="mr-2 mb-0 rightButtons" @click="login()" color="#F89416" type="filled">Login</vs-button>
              <vs-button @click="goToHome()" class="mx-2 rightButtons" color="dark" type="border">Continue As Guest</vs-button>
            </div>
            <p class="md:mt-8 sm:mt-4 mt-5 font-medium">Or login with</p>
            <div class="mt-3 flex">
              <GoogleLogin :params="params" :onSuccess="onSuccess" :onFailure="onFailure">
                <vs-button class="linkButtons" size="40px" radius color="" type="gradient">
                  <img src="@/assets/images/gmail.png" alt="">
                </vs-button>
              </GoogleLogin>
              <vs-button class="linkButtons" size="40px" radius color="" type="gradient">
                <img src="@/assets/images/apple.png" alt="">
              </vs-button>
              <facebook-login class="face-button button"
                appId="428511151995837"
                @login="getUserData"
                @logout="onLogout"
                @get-initial-status="getUserData">
              </facebook-login>
              <!-- <vs-button class="linkButtons" size="40px" radius color="" type="gradient">
                <img src="@/assets/images/facebook.png" alt="">
              </vs-button> -->
            </div>
          </div>
        </div>
      </template>
  </VuePerfectScrollbar>
  </div>
</template>

<script>
import axios from '../axios.js'
import GoogleLogin from 'vue-google-login';
import facebookLogin from 'facebook-login-vuejs';
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'LogIn',
  data () {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30
      },
      params: {
        client_id: '864474919684-efcme1s4g5e367d4ggtmc4sm4pocvvdt.apps.googleusercontent.com',
        renderParams: {
          width: 250,
          height: 50,
          longtitle: true
        }
      },
      window: {
        width: 0
      },
      loginData: {
        mobile: '',
        password: ''
      },
      profile: {}
    }
  },
  methods: {
    getUserData (data) {
      console.log(data)
    },
    goToPage (name) {
      this.$router.push({name})
    },
    onLogout () {},
    onSuccess(googleUser) {
      this.profile = googleUser.getBasicProfile()
      this.login('google')
    },
    onFailure () {
    },
    // login (item) {
    //   if (item === 'google') {
    //     this.loginData= {
    //       email: this.profile.getEmail(),
    //       google_id: this.profile.getId()
    //     }
    //   }
    // },
    goToHome () {
      this.$router.push({name: 'home'})
    },
    login () {
      this.$vs.loading()
      axios.post('v1/logIn', this.loginData)
      .then(response => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          localStorage.setItem('access_token', response.data.payload.auth_token)
          localStorage.setItem('user_data', JSON.stringify(response.data.payload))
          localStorage.setItem('loggedIn', true)
          localStorage.setItem('cart', JSON.stringify([]))
          this.$router.push({name: 'home'}).catch(() => {})
          this.$vs.loading.close()
        }
      })
    },
    handleResize() {
      this.window.width = window.innerWidth;
    }
  },
  components: {
    VuePerfectScrollbar,
    GoogleLogin,
    facebookLogin
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>
<style >
.face-button > button {
  font-size: 0px !important;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  width: 39px;
  height: 40px;
  border-radius: 50%;
  font-size: 0px;
  padding: 0px !important;
  min-width: 0px !important;
}
.face-button > button > img {
  left: 3px !important;
}
</style>

<style scoped>

.backgroundImage {
  background-image: url("../assets/images/footer.jpg");
}
.transbox {
  position: fixed;
  /* width: 50%;
  height: 100%; */
  background-color: #05d8dc80;
  /* background-image: linear-gradient(to bottom, #05d8dc80,rgba(89, 131, 127, 0.596)); */
  opacity: 0.85;
}
.leftButtons {
  border: 1px solid white;
  color: white; 
  padding: 5px 50px;
  border-radius: 5px !important;
}
.leftButtons:hover, .leftButtons:focus {
  color: #00DCDC !important;
  background: transparent !important;
}
.rightButtons {
  padding: 5px 37px;
  border-radius: 2px !important;
}
.face-button > button {
  font-size: 0px !important;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  width: 39px;
  height: 40px;
  border-radius: 50%;
  font-size: 0px;
  padding: 0px !important;
  min-width: 0px !important;
}
.btn-drop {
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0px;
  color: #00DCDC !important;
  padding: 10px 0px !important;
}
.phoneInput {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0px;
  padding: 10px 2px;
}
.passwordInput {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0px;
  padding: 10px 2px;
  width: 330px;
}
.linkButtons {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  margin-right: 15px;
}
.rightDiv {
  background-image: url(../assets/images/joinus.svg);
  background-size: 450px;
}
@media (max-width: 770px) {
  .phoneInput {
    width: 70%;
  }
  .passwordInput {
    width: 81%;
  }
  .rightButtons {
    padding: 5px 25px;
  }
}
@media (max-width: 1280px) {
  .rightDiv {
    background-size: 400px;
  }
}
@media (max-width: 775px) {
  .rightButtons {
    margin: 5px 10px 0px 0px;
  }
  .rightDiv {
    background-size: 350px !important;
  }
}
@media (max-width: 640px) {
  .rightDiv {
    background-size: 250px !important;
  }
  .rightButtons {
    padding: 5px 15px !important;
  }
}
</style>